<template>
  <div class="payment-method-type-selection">
    <div class="control-input" role="radiogroup" aria-label="Vælg betalingsmetode">
      <label>Payment method</label>

      <ul class="radio-group horisontal">
        <!-- Test specific -->
        <li v-if="staticWorkflowProduct.paymentMethodTypeTest">
          <label for="paymentMethodTypeTest" aria-label="Test-betalingsmetode">
            <input role="radio" type="radio" id="paymentMethodTypeTest" v-bind:value="'Test'"
              v-model="product.paymentMethodType" v-on:change="onPaymentMethodTypeChange()" name="paymentMethodType"
              aria-label="Test-betalingsmetode" />
            Test
            <div class="check"></div>
            <div class="check-wrap"></div>
            <i class="payment-icon"></i>
          </label>
        </li>

        <!-- Card specific -->
        <li v-if="staticWorkflowProduct.paymentMethodTypeCard">
          <label for="paymentMethodTypeCard" aria-label="Betalingskort">
            <input role="radio" type="radio" id="paymentMethodTypeCard" v-bind:value="'Card'"
              v-model="product.paymentMethodType" v-on:change="onPaymentMethodTypeChange()" name="paymentMethodType"
              aria-label="Betalingskort" />
            Card
            <div class="check"></div>
            <div class="check-wrap"></div>
            <i class="payment-icon"></i>
          </label>
        </li>

        <!-- MobilePay specific -->
        <li v-if="staticWorkflowProduct.paymentMethodTypeMobilePay">
          <label for="paymentMethodTypeMobilePay" aria-label="MobilePay">
            <input role="radio" type="radio" id="paymentMethodTypeMobilePay" v-bind:value="'MobilePay'"
              v-model="product.paymentMethodType" v-on:change="onPaymentMethodTypeChange()" name="paymentMethodType"
              aria-label="MobilePay" />
            MobilePay
            <div class="check"></div>
            <div class="check-wrap"></div>
            <i class="payment-icon"></i>
          </label>
        </li>

        <!-- Betalingsservice specific -->
        <li v-if="
          staticWorkflowProduct.paymentMethodTypeBetalingsservice &&
          (product.paymentType === 'Recurring' ||
            sessionRequest.workflow.serviceType === 'Upgrade')
        ">
          <label for="paymentMethodTypeBetalingsservice" aria-label="Betalingsservice">
            <input role="radio" type="radio" id="paymentMethodTypeBetalingsservice" v-bind:value="'Betalingsservice'"
              v-model="product.paymentMethodType" v-on:change="onPaymentMethodTypeChange()" name="paymentMethodType"
              aria-label="Betalingsservice" />
            Betalingsservice
            <div class="check"></div>
            <div class="check-wrap"></div>
            <i class="payment-icon"></i>
          </label>
        </li>

        <!-- Giro specific -->
        <li v-if="staticWorkflowProduct.paymentMethodTypeGiro">
          <label for="paymentMethodTypeGiro" aria-label="Indbetalingskort">
            <input role="radio" type="radio" id="paymentMethodTypeGiro" v-bind:value="'Giro'"
              v-model="product.paymentMethodType" v-on:change="onPaymentMethodTypeChange()" name="paymentMethodType"
              aria-label="Indbetalingskort" />
            Giro
            <div class="check"></div>
            <div class="check-wrap"></div>
            <i class="payment-icon"></i>
          </label>
        </li>

        <!-- Invoice specific -->
        <li v-if="staticWorkflowProduct.paymentMethodTypeInvoice">
          <label for="paymentMethodTypeInvoice" aria-label="Faktura">
            <input role="radio" type="radio" id="paymentMethodTypeInvoice" v-bind:value="'Invoice'"
              v-model="product.paymentMethodType" v-on:change="onPaymentMethodTypeChange()" name="paymentMethodType"
              aria-label="Faktura" />
            Invoice
            <div class="check"></div>
            <div class="check-wrap"></div>
            <i class="payment-icon"></i>
          </label>
        </li>

        <!-- EAN specific -->
        <li v-if="staticWorkflowProduct.paymentMethodTypeEAN">
          <label for="paymentMethodTypeEAN" aria-label="EAN">
            <input role="radio" type="radio" id="paymentMethodTypeEAN" v-bind:value="'EAN'"
              v-model="product.paymentMethodType" v-on:change="onPaymentMethodTypeChange()" name="paymentMethodType"
              aria-label="EAN" />
            EAN
            <div class="check"></div>
            <div class="check-wrap"></div>
            <i class="payment-icon"></i>
          </label>
        </li>

        <!-- Direct debit specific -->
        <li v-if="
          staticWorkflowProduct.paymentMethodTypeDirectDebit &&
          (product.paymentType === 'Recurring' ||
            sessionRequest.workflow.serviceType === 'Upgrade')
        ">
          <label for="paymentMethodTypeDirectDebit" aria-label="Direct debit">
            <input role="radio" type="radio" id="paymentMethodTypeDirectDebit" v-bind:value="'DirectDebit'"
              v-model="product.paymentMethodType" v-on:change="onPaymentMethodTypeChange()" name="paymentMethodType"
              aria-label="Direct debit" />
            Direct debit
            <div class="check"></div>
            <div class="check-wrap"></div>
            <i class="payment-icon"></i>
          </label>
        </li>

      </ul>
    </div>

    <!-- Test specific -->
    <onboarding-payment-method-type-selection-test v-if="product.paymentMethodType === 'Test'"
      v-bind:sessionRequest="this.sessionRequest" v-bind:product="this.product" />

    <!-- MobilePay specific -->
    <onboarding-payment-method-type-selection-mobile-pay v-else-if="product.paymentMethodType === 'MobilePay'"
      v-bind:sessionRequest="this.sessionRequest" v-bind:product="this.product" />

    <!-- Betalingsservice specific -->
    <onboarding-payment-method-type-selection-betalingsservice
      v-else-if="product.paymentMethodType === 'Betalingsservice'" v-bind:sessionRequest="this.sessionRequest"
      v-bind:product="this.product" />

    <!-- EAN specific -->
    <onboarding-payment-method-type-selection-ean v-else-if="product.paymentMethodType === 'EAN'"
      v-bind:sessionRequest="this.sessionRequest" v-bind:product="this.product" />
  </div>
</template>

<script>
import Vue from "vue";
import OnboardingPaymentMethodTypeSelectionTest from "./OnboardingPaymentMethodTypeSelectionTest";
import OnboardingPaymentMethodTypeSelectionMobilePay from "./OnboardingPaymentMethodTypeSelectionMobilePay";
import OnboardingPaymentMethodTypeSelectionBetalingsservice from "./OnboardingPaymentMethodTypeSelectionBetalingsservice";
import OnboardingPaymentMethodTypeSelectionEan from "./OnboardingPaymentMethodTypeSelectionEan";
import { formats } from "../../mixins/formats.js";
import { changeEvents } from "../../mixins/changeEvents.js";
import { relayHandler } from "../../mixins/relayHandler.js";
import { sessionRequestGatewayHandler } from "../../mixins/sessionRequestGatewayHandler.js";

export default Vue.extend({
  name: "OnboardingPaymentMethodTypeSelection",
  mixins: [formats, changeEvents, relayHandler, sessionRequestGatewayHandler],
  components: {
    OnboardingPaymentMethodTypeSelectionTest,
    OnboardingPaymentMethodTypeSelectionMobilePay,
    OnboardingPaymentMethodTypeSelectionBetalingsservice,
    OnboardingPaymentMethodTypeSelectionEan,
  },
  props: {
    sessionRequest: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    staticWorkflowProduct: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.initialisePaymentMethodTypeSelection();
  },
  updated() {
    this.initialisePaymentMethodTypeSelection();
  },
  methods: {
    /**
     * Initialise
     */
    initialisePaymentMethodTypeSelection: function () {
      if (!this.product.paymentMethodType) {
        //  Build array to check against
        let paymentMethodTypes = [];

        //  Make "Test" available
        if (this.staticWorkflowProduct.paymentMethodTypeTest) {
          paymentMethodTypes.push("Test");
        }

        //  Make "Card" available
        if (this.staticWorkflowProduct.paymentMethodTypeCard) {
          paymentMethodTypes.push("Card");
        }

        //  Make "MobilePay" available
        if (this.staticWorkflowProduct.paymentMethodTypeMobilePay) {
          paymentMethodTypes.push("MobilePay");
        }

        //  Make "Betalingsservice" available
        if (
          this.staticWorkflowProduct.paymentMethodTypeBetalingsservice &&
          this.product.paymentType === "Recurring"
        ) {
          paymentMethodTypes.push("Betalingsservice");
        }

        //  Make "Giro" available
        if (this.staticWorkflowProduct.paymentMethodTypeGiro) {
          paymentMethodTypes.push("Giro");
        }

        //  Make "Invoice" available
        if (this.staticWorkflowProduct.paymentMethodTypeInvoice) {
          paymentMethodTypes.push("Invoice");
        }

        //  Make "EAN" available
        if (this.staticWorkflowProduct.paymentMethodTypeEAN) {
          paymentMethodTypes.push("EAN");
        }

        //  Make "Direct debit" available
        if (this.staticWorkflowProduct.paymentMethodTypeDirectDebit) {
          paymentMethodTypes.push("DirectDebit");
        }

        //  Just one available? Select it!
        if (paymentMethodTypes.length === 1) {
          this.product.paymentMethodType = paymentMethodTypes[0];

          this.onPaymentMethodTypeChange();
        }
      }
    },

    /**
     * Set msisdn and nationalId and trigger mixin when PaymentMethodType is changed
     */
    onPaymentMethodTypeChange: function () {
      window.console.log(
        "onPaymentMethodTypeChange triggered: " + this.product.paymentMethodType
      );

      //  Define msisdn, if not already
      if (!this.product.msisdn && this.sessionRequest.contactData.msisdn) {
        this.product.msisdn = this.sessionRequest.contactData.msisdn;
      }

      //  Define nationalId, if not already
      if (
        !this.product.nationalId &&
        this.sessionRequest.contactData.nationalId
      ) {
        this.product.nationalId = this.sessionRequest.contactData.nationalId;
      }

      //  Emit
      this.$emit("whenPaymentMethodTypeChanged");

      //  Force update needed to update selection
      this.$forceUpdate();
    },
  },
});
</script>
