<template>
  <div>
    <!-- Modal -->
    <div v-if="workflow" class="modal slideIn">
      <div class="modal-content">
        <!-- Header -->
        <div class="view-header-wrap uik-entity-list-header">
          <h2
            v-if="
              this.$root.user.role === 'MerchantAdmin' && workflow.workflowGuid
            "
            class="title"
          >
            Edit workflow: {{ workflow.name }}
          </h2>
          <h2 v-else-if="workflow.workflowGuid" class="title">
            Workflow: {{ workflow.name }}
          </h2>
          <h2 v-else class="title">Create new workflow</h2>

          <!-- Buttons -->
          <div
            v-if="this.$root.user.role === 'MerchantAdmin'"
            class="right-aligned popover-wrapper"
          >
            <button
              v-if="workflow.workflowGuid"
              type="button"
              class="uik-btn__link btn"
              v-on:click="closeWorkflowModal()"
            >
              Close
            </button>
            <button
              v-else
              type="button"
              class="uik-btn__base btn"
              v-on:click="closeWorkflowModal()"
            >
              Cancel
            </button>
            <button
              v-if="workflow.workflowGuid"
              type="button"
              class="uik-btn__link btn btn-archive"
              v-on:click="archiveWorkflow()"
            >
              Archive
            </button>
            <button
              v-if="workflow.workflowGuid"
              type="button"
              class="uik-btn__base btn btn-primary"
              v-on:click="updateWorkflow()"
            >
              Update
            </button>
            <button
              v-else
              type="button"
              class="uik-btn__base btn btn-primary"
              v-on:click="insertWorkflow()"
            >
              Create
            </button>
          </div>
          <div v-else class="right-aligned popover-wrapper">
            <button
              v-if="workflow.workflowGuid"
              type="button"
              class="uik-btn__link btn"
              v-on:click="closeWorkflowModal()"
            >
              Close
            </button>
          </div>
        </div>

        <!-- Name / Service Type -->
        <div
          v-bind:class="[
            this.$root.user.role === 'MerchantAdmin'
              ? 'block-wrap'
              : 'block-wrap no-pointer-events',
          ]"
        >
          <div class="block">
            <div class="row">
              <!-- Campaign name -->
              <div class="col-sm-6">
                <div class="block-header">
                  <label for="workflowName">
                    <h3>Name your workflow</h3>
                  </label>
                </div>
                <input
                  type="text"
                  id="workflowName"
                  class="uik-input__input"
                  placeholder="Give your workflow a name"
                  required
                  v-bind:disabled="
                    this.$root.user.role === 'MerchantAdmin' ? false : true
                  "
                  v-model="workflow.name"
                />
              </div>

              <!-- Service Type -->
              <div class="col-sm-6">
                <div class="block-header inline-tooltip">
                  <h3>Select intention</h3>

                  <!-- Tooltip -->
                  <tool-tip
                    v-bind:tooltip="'Use sign-up for all things new and upgrade for existing contacts'"
                  />
                </div>
                <div class="service-selection-wrap">
                  <ul class="service-select-group">
                    <li
                      v-for="serviceType in serviceTypes"
                      v-bind:key="serviceType.value"
                    >
                      <label>
                        <input
                          type="radio"
                          v-bind:value="serviceType.value"
                          v-model="workflow.serviceType"
                        />
                        <div class="service-select">
                          <div
                            class="icon"
                            v-bind:class="serviceType.icon"
                          ></div>
                          <span class="name">{{ serviceType.label }}</span>
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div v-if="workflow.serviceType === 'Upgrade'" class="block">
            <div class="row">
              <!-- Permissions -->
              <div class="col-sm-6">
                <div class="block-header">
                  <h3>Subscription permissions</h3>
                  <small class="input-helper-text"
                    >Define senior agents' permissions. Does not apply to junior
                    agents.</small
                  >
                </div>
                <ul class="checkbox-group horizontal">
                  <li>
                    <label for="restartSubscription">
                      <input
                        type="checkbox"
                        id="restartSubscription"
                        v-bind:disabled="
                          this.$root.user.role === 'MerchantAdmin'
                            ? false
                            : true
                        "
                        v-model="restartSubscription"
                      />
                      Restart subscription
                      <div class="check"></div>
                    </label>
                  </li>
                  <li>
                    <label for="pauseSubscription">
                      <input
                        type="checkbox"
                        id="pauseSubscription"
                        v-bind:disabled="
                          this.$root.user.role === 'MerchantAdmin'
                            ? false
                            : true
                        "
                        v-model="pauseSubscription"
                      />
                      Pause subscription
                      <div class="check"></div>
                    </label>
                  </li>
                  <li>
                    <label for="cancelSubscription">
                      <input
                        type="checkbox"
                        id="cancelSubscription"
                        v-bind:disabled="
                          this.$root.user.role === 'MerchantAdmin'
                            ? false
                            : true
                        "
                        v-model="cancelSubscription"
                      />
                      Cancel subscription
                      <div class="check"></div>
                    </label>
                  </li>
                  <li>
                    <label for="setExpiration">
                      <input
                        type="checkbox"
                        id="setExpiration"
                        v-bind:disabled="
                          this.$root.user.role === 'MerchantAdmin'
                            ? false
                            : true
                        "
                        v-model="setExpiration"
                      />
                      Set expiration
                      <div class="check"></div>
                    </label>
                  </li>
                </ul>
              </div>

              <!-- Retention -->
              <div class="col-sm-6">
                <div class="block-header">
                  <h3>Payment methods for renewal</h3>
                  <small class="input-helper-text"
                    >Define which payments methods are allowed if renewal is
                    needed during the upgrade.</small
                  >
                </div>
                <ul class="checkbox-group horizontal">
                  <li>
                    <label for="retentionPaymentMethodTypeTest">
                      <input
                        type="checkbox"
                        id="retentionPaymentMethodTypeTest"
                        v-bind:disabled="
                          this.$root.user.role === 'MerchantAdmin'
                            ? false
                            : true
                        "
                        v-model="retentionPaymentMethodTypeTest"
                      />
                      Test
                      <div class="check"></div>
                    </label>
                  </li>
                  <li>
                    <label for="retentionPaymentMethodTypeCard">
                      <input
                        type="checkbox"
                        id="retentionPaymentMethodTypeCard"
                        v-bind:disabled="
                          this.$root.user.role === 'MerchantAdmin'
                            ? false
                            : true
                        "
                        v-model="retentionPaymentMethodTypeCard"
                      />
                      Card
                      <div class="check"></div>
                    </label>
                  </li>
                  <li>
                    <label for="retentionPaymentMethodTypeMobilePay">
                      <input
                        type="checkbox"
                        id="retentionPaymentMethodTypeMobilePay"
                        v-bind:disabled="
                          this.$root.user.role === 'MerchantAdmin'
                            ? false
                            : true
                        "
                        v-model="retentionPaymentMethodTypeMobilePay"
                      />
                      MobilePay
                      <div class="check"></div>
                    </label>
                  </li>
                  <li>
                    <label for="retentionPaymentMethodTypeBetalingsservice">
                      <input
                        type="checkbox"
                        id="retentionPaymentMethodTypeBetalingsservice"
                        v-bind:disabled="
                          this.$root.user.role === 'MerchantAdmin'
                            ? false
                            : true
                        "
                        v-model="retentionPaymentMethodTypeBetalingsservice"
                      />
                      Betalingsservice
                      <div class="check"></div>
                    </label>
                  </li>
                  <li>
                    <label for="retentionPaymentMethodTypeDirectDebit">
                      <input
                        type="checkbox"
                        id="retentionPaymentMethodTypeDirectDebit"
                        v-bind:disabled="
                          this.$root.user.role === 'MerchantAdmin'
                            ? false
                            : true
                        "
                        v-model="retentionPaymentMethodTypeDirectDebit"
                      />
                      Direct debit
                      <div class="check"></div>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            v-bind:class="[
              this.$root.user.role === 'MerchantAdmin'
                ? 'block'
                : 'block no-pointer-events',
            ]"
          >
            <div class="row">
              <!-- checkoutMessage -->
              <div class="col-sm-12">
                <div class="block-header">
                  <label for="checkoutMessage">
                    <h3>Approval message</h3>
                  </label>
                  <small class="input-helper-text"
                    >Used when sending the Lead a SMS to approve and complete
                    the process. The message is appended by a shortened
                    URL.</small
                  >
                </div>
                <textarea
                  id="checkoutMessage"
                  class="uik-input__input"
                  rows="3"
                  placeholder="Hi. Thanks for the talk. Please approve by clicking this link:"
                  required
                  v-bind:disabled="
                    this.$root.user.role === 'MerchantAdmin' ? false : true
                  "
                  v-model="workflow.checkoutMessage"
                ></textarea>
              </div>
            </div>
            <br />
            <div class="row">
              <!-- senderAlias -->
              <div class="col-sm-6">
                <div class="control-input">
                  <label for="senderAlias">SMS sender name</label>
                  <input
                    type="text"
                    id="senderAlias"
                    class="uik-input__input"
                    placeholder="Please define a meaningful name (max 11 chars.)"
                    maxlength="11"
                    v-model="workflow.senderAlias"
                    required
                  />
                </div>
              </div>

              <!-- urlShortener -->
              <div class="col-sm-6">
                <div class="control-input">
                  <label> URL shortener </label>
                  <ul class="radio-group horizontal">
                    <li
                      v-for="urlShortener in this.$root.helpers.urlShorteners"
                      v-bind:key="urlShortener.value"
                    >
                      <label v-bind:for="'urlShortener' + urlShortener.value">
                        <input
                          type="radio"
                          v-bind:id="'urlShortener' + urlShortener.value"
                          v-bind:value="urlShortener.value"
                          v-model="workflow.urlShortener"
                        />
                        {{ urlShortener.label }}
                        <div class="check"></div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Columns end -->
        <sign-up-products
          v-if="workflow.serviceType === 'SignUp'"
          ref="signUpProducts"
          v-bind:workflow="this.workflow"
        />
        <upgrade-products
          v-if="workflow.serviceType === 'Upgrade'"
          ref="upgradeProducts"
          v-bind:workflow="this.workflow"
        />
        <contact-data-fields
          v-if="
            workflow.serviceType === 'SignUp' ||
            workflow.serviceType === 'Upgrade'
          "
          ref="contactDataFields"
          v-bind:workflow="this.workflow"
        />
        <meta-data-fields
          v-if="
            workflow.serviceType === 'SignUp' ||
            workflow.serviceType === 'Upgrade'
          "
          ref="metaDataFields"
          v-bind:element="this.workflow"
        />
      </div>
    </div>
    <div
      v-if="workflow"
      v-on:click="closeWorkflowModal()"
      class="of-modal-bg fadeIn"
    ></div>
  </div>
</template>

<script>
import Vue from "vue";
import MetaDataFields from "../shared/MetaDataFields";
import ContactDataFields from "./WorkflowModalContactDataFields";
import SignUpProducts from "./WorkflowModalSignUpProducts";
import UpgradeProducts from "./WorkflowModalUpgradeProducts";
import ToolTip from "../ToolTip";
import { workflowHandler } from "../../mixins/workflowHandler.js";

export default Vue.extend({
  name: "WorkflowModal",
  mixins: [workflowHandler],
  components: {
    MetaDataFields,
    ContactDataFields,
    SignUpProducts,
    UpgradeProducts,
    ToolTip,
  },
  props: {
    workflow: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      restartSubscription: false,
      pauseSubscription: false,
      cancelSubscription: false,
      setExpiration: false,
      retentionPaymentMethodTypeTest: false,
      retentionPaymentMethodTypeCard: false,
      retentionPaymentMethodTypeMobilePay: false,
      retentionPaymentMethodTypeBetalingsservice: false,
      retentionPaymentMethodTypeDirectDebit: false,
      serviceTypes: [
        { label: "Sign-Up", icon: "icon-signup", value: "SignUp" },
        { label: "Upgrade", icon: "icon-upgrade", value: "Upgrade" },
      ],
    };
  },
  mounted() {
    //  Hotkey to close modal by Esc
    let self = this;
    window.addEventListener("keyup", function (e) {
      if (e.key === "Escape") {
        self.closeWorkflowModal();
      }
    });
  },
  created() {
    this.getPermissions();
    this.getRetentionPaymentMethodTypes();
  },
  methods: {
    /**
     * Close modal
     */
    closeWorkflowModal: function () {
      this.$emit("closeWorkflowModal");
    },
  },
});
</script>
