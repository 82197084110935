<template>
  <div
    v-if="Object.keys(this.sessionRequest.agreementData).length > 0"
    class="upgrade"
  >
    <!-- Info table -->
    <onboarding-upgrade-review-table
      v-bind:sessionRequest="this.sessionRequest"
    />
    <hr />
    <h2>Actions</h2>
    <p>Perform one action at a time</p>

    <!-- Tabs -->
    <div class="nav">
      <ul class="nav-tabs">
        <!-- Edit Agreement -->
        <li
          v-if="this.hasAgreementUpdateProducts"
          class="nav-item"
          v-bind:class="
            sessionStatus === 'Completed' ? 'no-pointer-events' : null
          "
        >
          <button
            role="button"
            class="nav-link"
            v-bind:class="currentTab === 'AgreementUpdate' ? 'active' : null"
            v-on:click="changeUpgradeAction('AgreementUpdate')"
          >
            Update agreement
          </button>
        </li>

        <!-- Create AddOn -->
        <li
          v-if="this.hasAddOnProducts"
          class="nav-item"
          v-bind:class="
            sessionStatus === 'Completed' ? 'no-pointer-events' : null
          "
        >
          <button
            role="button"
            class="nav-link"
            v-bind:class="currentTab === 'CreateAddOn' ? 'active' : null"
            v-on:click="changeUpgradeAction('CreateAddOn')"
          >
            Create add-on
          </button>
        </li>

        <!-- Change PaymentMethod -->
        <li
          v-if="this.hasChangePaymentMethodsTypes"
          class="nav-item"
          v-bind:class="
            sessionStatus === 'Completed' ? 'no-pointer-events' : null
          "
        >
          <button
            role="button"
            class="nav-link"
            v-bind:class="
              currentTab === 'ChangePaymentMethod' ? 'active' : null
            "
            v-on:click="changeUpgradeAction('ChangePaymentMethod')"
          >
            Change payment method
          </button>
        </li>

        <!-- Subscription Actions -->
        <li
          v-if="this.hasSubscriptionPermissions"
          class="nav-item"
          v-bind:class="
            sessionStatus === 'Completed' ? 'no-pointer-events' : null
          "
        >
          <button
            role="button"
            class="nav-link"
            v-bind:class="
              currentTab === 'SubscriptionActions' ? 'active' : null
            "
            v-on:click="changeUpgradeAction('SubscriptionActions')"
          >
            Subscription actions
          </button>
        </li>
      </ul>
    </div>

    <!-- Tab Content -->
    <div class="tab-content">
      <!-- Tab : Update Agreement -->
      <div
        v-if="
          this.hasAgreementUpdateProducts && currentTab === 'AgreementUpdate'
        "
        class="tab-pane active"
      >
        <onboarding-upgrade-agreement-update
          ref="OnboardingUpgradeAgreement"
          v-bind:product="this.product"
          v-bind:staticWorkflowProduct="this.staticWorkflowProduct"
          v-bind:sessionRequest="this.sessionRequest"
        />
      </div>

      <!-- Tab : Create AddOn -->
      <div
        v-else-if="this.hasAddOnProducts && currentTab === 'CreateAddOn'"
        class="tab-pane active"
      >
        <onboarding-upgrade-add-on
          ref="OnboardingUpgradeAddOn"
          v-bind:product="this.product"
          v-bind:staticWorkflowProduct="this.staticWorkflowProduct"
          v-bind:sessionRequest="this.sessionRequest"
        />
      </div>

      <!-- Tab : Change PaymentMethod -->
      <div
        v-else-if="
          this.hasChangePaymentMethodsTypes &&
          currentTab === 'ChangePaymentMethod'
        "
        class="tab-pane active"
      >
        <onboarding-upgrade-change-payment-method
          ref="OnboardingUpgradePaymentMethod"
          v-bind:product="this.product"
          v-bind:staticWorkflowProduct="this.staticWorkflowProduct"
          v-bind:sessionRequest="this.sessionRequest"
        />
      </div>

      <!-- Tab : Subscription Actions -->
      <div
        v-else-if="
          this.hasSubscriptionPermissions &&
          currentTab === 'SubscriptionActions'
        "
        class="tab-pane active"
      >
        <onboarding-upgrade-subscription-actions
          ref="OnboardingUpgradeSubscription"
          v-bind:product="this.product"
          v-bind:staticWorkflowProduct="this.staticWorkflowProduct"
          v-bind:sessionRequest="this.sessionRequest"
        />
      </div>
    </div>
  </div>
  <p v-else aria-hidden="true">
    Loading subscription: "{{ this.$route.query.subscriptionGuid }}". Please
    ensure this is correct.
  </p>
</template>

<script>
import Vue from "vue";
import OnboardingUpgradeReviewTable from "./OnboardingUpgradeReviewTable";
import OnboardingUpgradeAddOn from "./OnboardingUpgradeAddOn";
import OnboardingUpgradeAgreementUpdate from "./OnboardingUpgradeAgreementUpdate";
import OnboardingUpgradeChangePaymentMethod from "./OnboardingUpgradeChangePaymentMethod";
import OnboardingUpgradeSubscriptionActions from "./OnboardingUpgradeSubscriptionActions";
import { formats } from "../../mixins/formats.js";
import { changeEvents } from "../../mixins/changeEvents.js";
import { sessionRequestHandler } from "../../mixins/sessionRequestHandler.js";
import { sessionRequestGatewayHandler } from "../../mixins/sessionRequestGatewayHandler.js";

export default Vue.extend({
  name: "OnboardingUpgrade",
  mixins: [
    formats,
    changeEvents,
    sessionRequestHandler,
    sessionRequestGatewayHandler,
  ],
  components: {
    OnboardingUpgradeReviewTable,
    OnboardingUpgradeAddOn,
    OnboardingUpgradeAgreementUpdate,
    OnboardingUpgradeChangePaymentMethod,
    OnboardingUpgradeSubscriptionActions,
  },
  props: {
    sessionRequest: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      product: {},
      currentTab: null,
      staticWorkflowProduct: {},
      hasAgreementUpdateProducts: false,
      hasAddOnProducts: false,
      hasChangePaymentMethodsTypes: false,
      hasSubscriptionPermissions: false,
      iframeGatewayUrl: null,
      iframeGatewayHeight: 650,
    };
  },
  created() {
    this.prepareUpgradeTypes();
    this.preparePaymentMethodTypes();
    this.preparePermissions();
    this.prepareTabs();
    this.getBetalingsserviceStartDateService();
  },
  methods: {
    /**
     * Detect upgrade types
     */
    prepareUpgradeTypes: function () {
      if (this.sessionRequest.workflow.upgradeProducts) {
        for (
          var i = 0;
          i < this.sessionRequest.workflow.upgradeProducts.length;
          i++
        ) {
          let upgradeType =
            this.sessionRequest.workflow.upgradeProducts[i].upgradeType;

          if (upgradeType === "AgreementUpdate") {
            this.hasAgreementUpdateProducts = true;
          } else if (upgradeType === "AddOn") {
            this.hasAddOnProducts = true;
          }
        }
      }
    },

    /**
     * Detect change payment method
     */
    preparePaymentMethodTypes: function () {
      if (
        this.sessionRequest.workflow.retentionPaymentMethodTypes &&
        this.sessionRequest.workflow.retentionPaymentMethodTypes.length > 0
      ) {
        this.hasChangePaymentMethodsTypes = true;
      }
    },

    /**
     * Detect subscription permissions, as user role
     */
    preparePermissions: function () {
      if (
        (this.$root.user.role === "MerchantAdmin" ||
          this.$root.user.role === "AgencyManager" ||
          this.$root.user.role === "SeniorAgent") &&
        this.sessionRequest.workflow.permissions &&
        this.sessionRequest.workflow.permissions.length > 0
      ) {
        this.hasSubscriptionPermissions = true;
      }
    },

    /**
     * Select tab
     */
    prepareTabs: function () {
      //  Select tab and product by query
      if (this.$route.query.product || this.$route.query.pre_product) {
        let index = 0;
        if (this.$route.query.product) {
          index = parseInt(this.$route.query.product) - 1;
        } else {
          index = parseInt(this.$route.query.pre_product) - 1;
        }

        //  Select AgreementUpdate tab
        if (
          this.sessionRequest.workflow.upgradeProducts[index].upgradeType ===
          "AgreementUpdate"
        ) {
          this.changeUpgradeAction("AgreementUpdate");
        }

        //  Select AddOn tab
        else if (
          this.sessionRequest.workflow.upgradeProducts[index].upgradeType ===
          "AddOn"
        ) {
          this.changeUpgradeAction("CreateAddOn");
        }
      }

      //  Select Change PaymentMethod tab by query
      else if (
        this.hasChangePaymentMethodsTypes &&
        this.$route.query.action === "ChangePaymentMethod"
      ) {
        this.changeUpgradeAction("ChangePaymentMethod");
      }

      //  Select Subscription Actions tab by query
      else if (
        this.hasSubscriptionPermissions &&
        this.$route.query.action === "SubscriptionActions"
      ) {
        this.changeUpgradeAction("SubscriptionActions");
      }

      //  Select first available tab by default
      else if (this.hasAgreementUpdateProducts) {
        this.changeUpgradeAction("AgreementUpdate");
      } else if (this.hasAddOnProducts) {
        this.changeUpgradeAction("CreateAddOn");
      } else if (this.hasChangePaymentMethodsTypes) {
        this.changeUpgradeAction("ChangePaymentMethod");
      } else if (this.hasSubscriptionPermissions) {
        this.changeUpgradeAction("SubscriptionActions");
      }
    },

    /**
     * Handle change of action
     * @param {string} actionType
     */
    changeUpgradeAction: function (actionType) {
      //  Reset session status
      this.sessionStatus = null;

      //  Agreement Update
      if (actionType === "AgreementUpdate") {
        this.product = {};

        //  Select AgreementUpdate product by query
        if (this.$route.query.product || this.$route.query.pre_product) {
          let index = 0;
          if (this.$route.query.product) {
            index = parseInt(this.$route.query.product) - 1;
          } else {
            index = parseInt(this.$route.query.pre_product) - 1;
          }

          if (
            this.sessionRequest.workflow.upgradeProducts[index].upgradeType ===
            "AgreementUpdate"
          ) {
            this.staticWorkflowProduct =
              this.sessionRequest.workflow.upgradeProducts[index];
            this.onUpgradeProductChange(this.staticWorkflowProduct);
          }
        }

        //  Default to first AgreementUpdate product
        else {
          for (
            var d = 0;
            d < this.sessionRequest.workflow.upgradeProducts.length;
            d++
          ) {
            if (
              this.sessionRequest.workflow.upgradeProducts[d].upgradeType ===
              "AgreementUpdate"
            ) {
              this.staticWorkflowProduct =
                this.sessionRequest.workflow.upgradeProducts[d];
              this.onUpgradeProductChange(this.staticWorkflowProduct);
              break;
            }
          }
        }
      }

      //  Create AddOn
      else if (actionType === "CreateAddOn") {
        this.product = {};

        //  Select AddOn product by query
        if (this.$route.query.product || this.$route.query.pre_product) {
          let index = 0;
          if (this.$route.query.product) {
            index = parseInt(this.$route.query.product) - 1;
          } else {
            index = parseInt(this.$route.query.pre_product) - 1;
          }

          if (
            this.sessionRequest.workflow.upgradeProducts[index].upgradeType ===
            "AddOn"
          ) {
            this.staticWorkflowProduct =
              this.sessionRequest.workflow.upgradeProducts[index];
            this.onUpgradeProductChange(this.staticWorkflowProduct);
          }
        }

        //  Default to first AddOn product
        else {
          for (
            var c = 0;
            c < this.sessionRequest.workflow.upgradeProducts.length;
            c++
          ) {
            if (
              this.sessionRequest.workflow.upgradeProducts[c].upgradeType ===
              "AddOn"
            ) {
              this.staticWorkflowProduct =
                this.sessionRequest.workflow.upgradeProducts[c];
              this.onUpgradeProductChange(this.staticWorkflowProduct);
              break;
            }
          }
        }
      }
      //  Change PaymentMethod
      else if (actionType === "ChangePaymentMethod") {
        //  Define product by agreementData
        this.product = this.sessionRequest.agreementData;
        this.product.paymentType = "Recurring";

        //  Define paymentMethodTypes in staticWorkflowProduct
        this.staticWorkflowProduct = {};

        if (this.hasChangePaymentMethodsTypes) {
          for (
            var b = 0;
            b < this.sessionRequest.workflow.retentionPaymentMethodTypes.length;
            b++
          ) {
            let paymentMethodType =
              this.sessionRequest.workflow.retentionPaymentMethodTypes[b];

            if (paymentMethodType === "Test") {
              this.staticWorkflowProduct.paymentMethodTypeTest = true;
            } else if (paymentMethodType === "Card") {
              this.staticWorkflowProduct.paymentMethodTypeCard = true;
            } else if (paymentMethodType === "MobilePaySubscriptions") {
              this.staticWorkflowProduct.paymentMethodTypeMobilePay = true;
            } else if (paymentMethodType === "Betalingsservice") {
              this.staticWorkflowProduct.paymentMethodTypeBetalingsservice = true;
            } else if (paymentMethodType === "DirectDebit") {
              this.staticWorkflowProduct.paymentMethodTypeDirectDebit  = true;
            }
          }
        }

        //  Prefill by query
        this.handleDeeplinks();
      }

      //  Change tab
      this.toggleTab(actionType);
    },

    /**
     * Handle tab toggling
     * @param {int} tab
     */
    toggleTab: function (tab) {
      this.currentTab = tab;
    },

    /**
     * Force update needed to update selection
     */
    whenBsAppVerificationChange: function () {
      this.$forceUpdate();
    },
  },
});
</script>
