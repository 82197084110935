import axios from "axios";
import md5 from "js-md5";

export const workflowHandler = {
    methods: {

        /**
         * Get workflow by guid
         * @param {string} guid 
         */
        getWorkflow: function (guid) {

            let workflow = this.loadWorkflow(guid);
            if (workflow) {

                return {
                    workflowErrored: false,
                    workflow: workflow
                };
            } else {

                // window.console.log("workflow: get workflow from API");

                //  Request
                return axios
                    .get(this.$root.apiUrl + this.returnWorkflowApiPath(guid), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                    .then(response => {

                        return {
                            workflowErrored: false,
                            workflow: response.data
                        };
                    })
                    .catch(error => {

                        this.$root.handleErrorResponse(error);

                        return {
                            workflowErrored: true,
                            workflow: null
                        };
                    });
            }
        },

        /**
         * Insert workflow
         */
        insertWorkflow: function () {

            //  Validate
            var error = this.validateWorkflow();
            if (error) {
                this.$root.errorResponse(error);
                return;
            }

            //  Body
            let body = JSON.stringify(this.workflow);

            //  Request
            axios
                .post(this.$root.apiUrl + "/workflow", body, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken,
                        requestMD5: md5(body)
                    }
                })
                .then(response => {

                    //  Append guid, active and createdTs
                    //  TODO: timezone is off
                    this.workflow.workflowGuid = response.data.workflowGuid;
                    this.workflow.active = true;
                    this.workflow.createdTs = new Date()
                        .toJSON()
                        .slice(0, 19)
                        .replace("T", " ");

                    //  Push to list
                    this.$root.store.workflows.push(this.workflow);

                    //  Router
                    this.$root.viewEntity("workflow", response.data.workflowGuid);

                    //  Emit
                    this.$emit("insertedWorkflow", this.workflow);

                    //  Toast
                    this.$root.successResponse("Created", response);

                    //  Force update needed to switch UI from Insert to Update
                    this.$forceUpdate();
                })
                .catch(error => {
                    this.$root.handleErrorResponse(error);
                });
        },

        /**
         * Update workflow
         */
        updateWorkflow: function () {

            //  Validate
            var error = this.validateWorkflow();
            if (error) {
                this.$root.errorResponse(error);
                return;
            }

            if (this.workflow.signUpProducts) {
                this.workflow.signUpProducts.map((product) => {
                    if (!product.scheduleStartWithOneOff) {
                        product.oneOffAmount = '';
                    }
                    return product;
                })
            }

            //  Body
            let body = JSON.stringify(this.workflow);

            //  Request
            axios
                .put(
                    this.$root.apiUrl + "/workflow/" + this.workflow.workflowGuid,
                    body, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken,
                        requestMD5: md5(body)
                    }
                }
                )
                .then(response => {

                    //  Emit
                    this.$emit("updatedWorkflow", response);

                    //  Toast
                    this.$root.successResponse("Updated", response);
                })
                .catch(error => {
                    this.$root.handleErrorResponse(error);
                });
        },

        /**
         * Archive workflow
         */
        archiveWorkflow: function () {

            //  Verify role
            if (this.$root.user.role !== 'MerchantAdmin') {
                this.$root.errorResponse("You are not allowed to edit workflows");
                return;
            }

            //  Body
            let body = "";

            //  Confirmation
            if (confirm("Are you sure?")) {

                //  Request
                axios
                    .post(
                        this.$root.apiUrl +
                        "/workflow/" +
                        this.workflow.workflowGuid +
                        "/Archive",
                        body, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken,
                            requestMD5: md5(body)
                        }
                    }
                    )
                    .then(response => {

                        //  Emit
                        this.$emit("archivedWorkflow", response);

                        //  Toast
                        this.$root.successResponse("Archived", response);

                        //  Router
                        this.$root.viewEntity("workflow");
                    })
                    .catch(error => {
                        this.$root.handleErrorResponse(error);
                    });
            }
        },

        /**
         * Get workflows
         */
        getWorkflows: function () {

            //  Check storage
            if (this.$root.store.workflows.length > 0) {

                return {
                    workflowsErrored: false,
                    workflows: this.$root.store.workflows
                };
            } else {

                //  Request
                return axios
                    .get(this.$root.apiUrl + this.returnWorkflowsApiPath(), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                    .then(response => {
                        //  Build storage
                        this.$root.store.workflows = response.data;

                        return {
                            workflowsErrored: false,
                            workflows: this.$root.store.workflows
                        };
                    })
                    .catch(error => {

                        this.$root.handleErrorResponse(error);

                        return {
                            workflowsErrored: true,
                            workflows: []
                        };

                    });
            }
        },

        /**
         * Load workflow from storage
         * @param {string} guid 
         */
        loadWorkflow: function (guid) {

            if (this.$root.store.workflows.length > 0) {
                for (var i = 0; i < this.$root.store.workflows.length; i++) {
                    if (guid === this.$root.store.workflows[i].workflowGuid) {

                        // window.console.log("workflow: get workflow from storage");

                        return this.$root.store.workflows[i];
                    }
                }
            }

            return null;
        },

        /**
         * Validate workflow
         */
        validateWorkflow: function () {

            //  Verify role
            if (this.$root.user.role !== 'MerchantAdmin') {
                return "You are not allowed to edit workflows";
            }

            //  Ensure repeatable fields are updated
            if (this.workflow.serviceType === "SignUp") {
                this.$refs.signUpProducts.updateProducts();
            } else if (this.workflow.serviceType === "Upgrade") {
                this.$refs.upgradeProducts.updateProducts();
            } else {
                return "Missing intention";
            }
            this.$refs.contactDataFields.updateContactFields();
            this.$refs.metaDataFields.updateDataFields();

            //  Permissions
            this.setPermissions();

            //  Retention Payment Method Types
            this.setRetentionPaymentMethodTypes();

            //  Validation
            if (!this.workflow.name) {
                return "Missing name";
            } else if (!this.workflow.serviceType) {
                return "Missing intention";
            } else if (!this.workflow.checkoutMessage) {
                return "Missing approval message";
            } else if (!this.workflow.urlShortener) {
                return "Missing URL shortener";
            } else if (!this.workflow.senderAlias) {
                return "Missing SMS sender name";
            } else if (!this.workflow.countryCode) {
                return "Missing country for validation";
            } else if (this.workflow.serviceType === "SignUp") {

                //  Products
                if (!this.workflow.signUpProducts) {
                    return "Missing products";
                }

                //  Contact fields
                else if (!this.workflow.contactDataFields) {
                    return "Missing contact fields";
                }

                //  Business logic
                else {

                    //  Validate each product
                    for (let o = 0; o < this.workflow.signUpProducts.length; o++) {

                        let product = this.workflow.signUpProducts[o];

                        //  productName
                        if (!product.productName) {
                            return "A product is missing a name";
                        }

                        //  paymentType
                        else if (!product.paymentType) {
                            return "A product is missing a payment type";
                        }

                        //  paymentType = Single
                        else if (product.paymentType === 'Single') {

                            //  communicationCollectionGuid
                            if (!product.communicationCollectionGuid) {
                                return "A product is missing a communication";
                            }

                            //  paymentMethodType
                            else if (!product.paymentMethodTypeTest && !product.paymentMethodTypeCard && !product.paymentMethodTypeMobilePay && !product.paymentMethodTypeGiro && !product.paymentMethodTypeInvoice && !product.paymentMethodTypeEAN && !product.paymentMethodTypeDirectDebit) {
                                return "A product is missing a payment method type";
                            }

                            //  purposeAccountingCode
                            else if (product.purposeAccountingCode && product.purposeAccountingCode.length > 32) {
                                return "A product has a purpose accounting code longer than 32 characters. Please limit.";
                            }
                        }

                        //  paymentType = Recurring
                        else if (product.paymentType === 'Recurring') {

                            //  agreementType
                            if (!product.agreementType) {
                                return "A product is missing an agreement type";
                            }

                            //  agreementType = Personal
                            else if (product.agreementType === 'Personal') {

                                //  agreementName
                                if (!product.agreementName) {
                                    return "A product is missing an agreement name";
                                }

                                //  agreementName
                                else if (product.agreementName.length > 30) {
                                    return "A product has an agreement with name longer than 30 characters. Please limit.";
                                }

                                //  agreementDescription
                                else if (product.agreementDescription && product.agreementDescription.length > 60) {
                                    return "A product has an agreement with description longer than 60 characters. Please limit.";
                                }

                                //  purposeAccountingCode
                                else if (product.purposeAccountingCode && product.purposeAccountingCode.length > 32) {
                                    return "A product has an agreement with purpose accounting code longer than 32 characters. Please limit.";
                                }

                                //  communicationCollectionGuid
                                else if (!product.communicationCollectionGuid) {
                                    return "A product is missing a communication";
                                }
                            }

                            //  scheduleStartType
                            if (!product.scheduleStartTypeNow && !product.scheduleStartTypeFirst && !product.scheduleStartTypeCustom) {
                                return "A product is missing how scheduling should start";
                            }

                            //  paymentMethodType
                            if (!product.paymentMethodTypeTest && !product.paymentMethodTypeCard && !product.paymentMethodTypeMobilePay && !product.paymentMethodTypeBetalingsservice && !product.paymentMethodTypeGiro && !product.paymentMethodTypeInvoice && !product.paymentMethodTypeEAN && !product.paymentMethodTypeDirectDebit) {
                                return "A product is missing a payment method type";
                            }

                        } else {
                            return "A product has an unknown payment type";
                        }
                    }

                }
            } else if (this.workflow.serviceType === "Upgrade") {

                //  Products
                if (!this.workflow.upgradeProducts) {
                    return "Missing products";
                }

                //  Business logic
                else {

                    //  Validate each product
                    for (let o = 0; o < this.workflow.upgradeProducts.length; o++) {

                        let product = this.workflow.upgradeProducts[o];

                        //  productName
                        if (!product.productName) {
                            return "A product is missing a name";
                        }

                        //  upgradeType
                        else if (!product.upgradeType) {
                            return "A product is missing a upgrade type";
                        }

                        //  upgradeType = AddOn
                        else if (product.upgradeType === 'AddOn') {

                            //  addOnName
                            if (!product.addOnName) {
                                return "A product is missing an add-on name";
                            }

                            //  addOnName
                            else if (product.addOnName.length > 30) {
                                return "A product has an add-on with name longer than 30 characters. Please limit.";
                            }

                            //  addOnDescription
                            else if (product.addOnDescription && product.addOnDescription.length > 60) {
                                return "A product has an add-on with description longer than 60 characters. Please limit.";
                            }

                            //  purposeAccountingCode
                            else if (product.purposeAccountingCode && product.purposeAccountingCode.length > 32) {
                                return "A product has an add-on with purpose accounting code longer than 32 characters. Please limit.";
                            }
                        }
                    }
                }
            }

            return null;
        },

        /**
         * Set subscription actions
         */
        getPermissions: function () {
            if (this.workflow && this.workflow.permissions) {
                for (var i = 0; i < this.workflow.permissions.length; i++) {

                    let permission = this.workflow.permissions[i];

                    if (permission === "restartSubscription") {
                        this.restartSubscription = true;
                    } else if (permission === "pauseSubscription") {
                        this.pauseSubscription = true;
                    } else if (permission === "cancelSubscription") {
                        this.cancelSubscription = true;
                    } else if (permission === "setExpiration") {
                        this.setExpiration = true;
                    }
                }
            }
        },

        /**
         * Set subscription actions
         */
        setPermissions: function () {
            this.workflow.permissions = [];

            if (this.restartSubscription) {
                this.workflow.permissions.push("restartSubscription");
            }
            if (this.pauseSubscription) {
                this.workflow.permissions.push("pauseSubscription");
            }
            if (this.setExpiration) {
                this.workflow.permissions.push("setExpiration");
            }
            if (this.cancelSubscription) {
                this.workflow.permissions.push("cancelSubscription");
            }
        },

        /**
         * Get retention payment method types
         */
        getRetentionPaymentMethodTypes: function () {
            if (this.workflow && this.workflow.retentionPaymentMethodTypes) {
                for (
                    var i = 0; i < this.workflow.retentionPaymentMethodTypes.length; i++
                ) {

                    let paymentMethodType = this.workflow.retentionPaymentMethodTypes[i];

                    if (paymentMethodType === "Test") {
                        this.retentionPaymentMethodTypeTest = true;
                    } else if (paymentMethodType === "Card") {
                        this.retentionPaymentMethodTypeCard = true;
                    } else if (paymentMethodType === "MobilePaySubscriptions") {
                        this.retentionPaymentMethodTypeMobilePay = true;
                    } else if (paymentMethodType === "Betalingsservice") {
                        this.retentionPaymentMethodTypeBetalingsservice = true;
                    } else if (paymentMethodType === "DirectDebit") {
                        this.retentionPaymentMethodTypeDirectDebit = true;
                    }
                }
            }
        },

        /**
         * Set retention payment method types
         */
        setRetentionPaymentMethodTypes: function () {
            this.workflow.retentionPaymentMethodTypes = [];

            if (this.retentionPaymentMethodTypeTest) {
                this.workflow.retentionPaymentMethodTypes.push("Test");
            }
            if (this.retentionPaymentMethodTypeCard) {
                this.workflow.retentionPaymentMethodTypes.push("Card");
            }
            if (this.retentionPaymentMethodTypeMobilePay) {
                this.workflow.retentionPaymentMethodTypes.push(
                    "MobilePaySubscriptions"
                );
            }
            if (this.retentionPaymentMethodTypeBetalingsservice) {
                this.workflow.retentionPaymentMethodTypes.push("Betalingsservice");
            }
            if (this.retentionPaymentMethodTypeDirectDebit) {
                this.workflow.retentionPaymentMethodTypes.push("DirectDebit");
            }
        },

        /**
         * Return API endpoint
         * @param {string} guid 
         */
        returnWorkflowApiPath(guid) {
            //  Merchant
            if (this.$root.user.role === "MerchantAdmin") {
                return "/workflow/" + guid + "?merchantId=" +
                    encodeURIComponent(this.$root.user.merchantId)
            }

            //  Agency
            else {
                return (
                    "/workflow/" + guid + "?agencyId=" +
                    encodeURIComponent(this.$root.user.agencyId)
                );
            }
        },

        /**
         * Return API endpoint
         */
        returnWorkflowsApiPath() {
            //  Merchant
            if (this.$root.user.role === "MerchantAdmin") {
                return "/workflows?merchantId=" +
                    encodeURIComponent(this.$root.user.merchantId)
            }

            //  Agency
            else if (this.$root.merchant.merchantId && this.$root.user.agencyId) {
                return (
                    "/workflows?merchantId=" +
                    encodeURIComponent(this.$root.merchant.merchantId) +
                    "&agencyId=" +
                    encodeURIComponent(this.$root.user.agencyId)
                );
            }

            //  Error
            else {
                return null;
            }
        }
    }
};